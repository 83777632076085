<template>
  <section class="main-home">
    <div class="main-slogan">
      <img class="slogan-img" src="../../assets/images/slogan.png" />
    </div>
    <div class="btn-list">
      <Button
        icon="logo-apple"
        class="logo-ios btn-primary"
        @mouseenter.native="showIos"
        @mouseleave.native="notDisplayIos"
        @click="downloadApp(2)">
        AppStore
      </Button>
      <Button
        icon="logo-android"
        class="logo-android btn-primary"
        @mouseenter.native="showAndroid"
        @mouseleave.native="notDisplayAndroid"
        @click="downloadApp(1)">
        Android
      </Button>
      <transition
        name="fade"
        mode="out-in">
        <div
          class="displayBox"
          v-if="displayIos"
        >
          <!-- <div class="displayBox"> -->
          <img src="../../assets/images/ios_qr.png" alt="抖工IOS客户端下载" />
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div class="displayBox" v-if="displayAndroid">
          <img src="../../assets/images/android_qr.png" alt="抖工Android客户端下载"/>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        displayIos: false,
        displayAndroid: false,
        user: {},
        andriodAppUrl: "",
        iosAppUrl: "",
      }
    },
    methods: {
      showIos() {
        this.displayIos = true;
      },
      notDisplayIos() {
        this.displayIos = false;
      },
      showAndroid() {
        this.displayAndroid = true;
      },
      notDisplayAndroid() {
        this.displayAndroid = false;
      },
      toProtocolPage(type) {
        this.common.toProtocolPage(type);
      },
      jumpToMobile() {
        let flag = this.common.isMobile();
        if (flag) {
          window.location.href = this.CONSTANTS.MOBILE_INDEX_URL;
        }
      },
      downloadApp(type) {
        if (type === 1) {
          window.open(this.andriodAppUrl, '_blank');
        } else {
          window.open(this.iosAppUrl, '_blank');
        }
      },
      async getAppUrl() {
        let res = await this.$fetch("/sapi/v3/common/getAppUrl")
        if(res) {
          this.andriodAppUrl = res.data.andriod_url || 'http://www.dougongapp.com:8889/apk/qa.apk';
          // this.iosAppUrl = res.data.resData.ios_url;
          this.iosAppUrl = res.data.ios_url || "https://itunes.apple.com/cn/app/apple-store/id1483720561";
        }
      },


      // getAppUrl() {
      //   console.log("打印一下");
      //   this.$fetch(`/sapi/v3/common/getAppUrl`,{
      //       method: "get",
      //     }).then((res) => {
      //     if (res && res.code === "200") {
      //       this.andriodAppUrl = res.data.andriod_url || 'http://www.dougongapp.com:8889/apk/qa.apk';
      //       // this.iosAppUrl = res.data.resData.ios_url;
      //       this.iosAppUrl = res.data.ios_url || "https://itunes.apple.com/cn/app/apple-store/id1483720561";
      //     }
      //   });

      // },




      getAppQrCode() {

      }
    },
    mounted() {
      this.jumpToMobile();
      this.getAppUrl();
      // this.getAppQrCode();
    }
  }
</script>

<style lang="stylus">
flex()
  display flex
  justify-content space-between
  align-items center
btn-icon()
  width 100%
  height 100%
  display block
  border-radius 5px
  background-position center
  background-repeat no-repeat
  background-size cover

.main-home
  position absolute
  top 50%
  right 0
  width: 30.75em
  transform translateY(-50%)
  .main-slogan
    width 100%
    margin-bottom 50px
    img
      width 100%
  .btn-list
    flex()
    justify-content flex-end
    .btn-primary
      display inline-block
      background #fff
      border-radius 5px
      margin-left 10px
      width 140px
      height 50px
      font-size 1.2rem
      transition .15s opacity
      transition-delay .15s
      background-position center
      background-repeat no-repeat
      background-size cover
      box-shadow 1px 1px 7px 2px rgba(0,0,0,.54)
      transition opacity .5s
      i
        font-size 1.8rem
      &:hover, &:focus
        outline 0
        border none
        opacity 0.7
        transition opacity .5s
    .icon-ios
      btn-icon()
      background-image url('../../assets/images/ios_qr.png')
    .icon-android
      btn-icon()
      background-image url('../../assets/images/android_qr.png')
  .displayBox
    position absolute
    width 12.5em
    height 12.5em
    top 200px
    right 50px
    img
      width 100%
</style>
